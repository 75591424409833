<template>
  <!-- Modal -->
  <div class="modal fade" id="building_add">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Обйект қўшиш</h5>
          <button
            type="button"
            class="btn-close"
            data-mdb-dismiss="modal"
            aria-label="Close"
            id="buildings_add"
          ></button>
        </div>
        <form @submit.prevent="$emit('add', data)">
          <div class="modal-body">
            <div class="row gap-2">
              <div class="col-10 mx-auto">
                <label for="name1"> Номи: </label>
                <input
                  type="text"
                  id="name1"
                  class="form-control form-control-sm"
                  v-model="data.name"
                  required
                  placeholder="Номи"
                />
              </div>
              <div class="col-10 mx-auto">
                <label for="budget1">
                  Бюджет:
                  <span class="text-primary"
                    >{{ new Intl.NumberFormat().format(data.budget) }} сўм</span
                  >
                </label>
                <div class="input-group input-group-sm">
                  <input
                    type="number"
                    id="budget1"
                    class="form-control form-control-sm"
                    v-model="data.budget"
                    required
                    step="any"
                    placeholder="Бюджет"
                    min="0"
                  />
                  <span class="input-group-text bg-white">сўм</span>
                </div>
              </div>
              <div class="col-10 mx-auto">
                <label for="address1"> Манзил: </label>
                <input
                  type="text"
                  id="address1"
                  class="form-control form-control-sm"
                  v-model="data.address"
                  required
                  placeholder="Манзил"
                />
              </div>
              <div class="col-10 mx-auto">
                <label for="start_date1"> Бошлаш санаси: </label>
                <input
                  type="date"
                  id="start_date1"
                  class="form-control form-control-sm"
                  v-model="data.start_date"
                  required
                />
              </div>
              <div class="col-10 mx-auto">
                <label for="end_date1"> Тугаш санаси: </label>
                <input
                  type="date"
                  id="end_date1"
                  class="form-control form-control-sm"
                  v-model="data.end_date"
                  required
                />
              </div>
              <!-- <div class="col-10 mx-auto">
                <label for="map_long"> Узунлик: </label>
                <input
                  type="text"
                  id="map_long"
                  class="form-control form-control-sm"
                  v-model="data.map_long"
                  required
                  placeholder="Узунлик"
                />
              </div>
              <div class="col-10 mx-auto">
                <label for="map_lat"> Кенглик: </label>
                <input
                  type="text"
                  id="map_lat"
                  class="form-control form-control-sm"
                  v-model="data.map_lat"
                  required
                  placeholder="Кенглик"
                />
              </div> -->
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-outline-primary btn-sm"
              id="building_disabled"
            >
              <i class="fa-solid fa-circle-check"></i>
            </button>
            <button
              type="button"
              class="btn btn-outline-danger btn-sm"
              data-mdb-dismiss="modal"
            >
              <i class="fa-solid fa-circle-xmark"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BuildingAddItem",
  props: ["lat", "long"],
  data() {
    return {
      data: {
        name: "",
        address: "",
        budget: null,
        start_date: "",
        end_date: "",
        map_long: "",
        map_lat: "",
        status: "wait",
      },
    };
  },
  watch: {
    long(e) {
      this.data.map_long = "";
      this.data.map_long = String(e);
    },
    lat(e) {
      this.data.map_lat = "";
      this.data.map_lat = String(e);
    },
  },
};
</script>

<!-- <style lang="scss">
</style> -->
