<template>
  <button
    type="button"
    class="btn btn-dark btn-floating"
    @click="nightMode = !nightMode"
  >
    <i class="fa-solid fa-moon" id="icon"></i>
  </button>
</template>

<script>
export default {
  name: "DarkMode",
  data() {
    return {
      nightMode: localStorage.getItem("nightMode"),
    };
  },
  watch: {
    nightMode() {
      if (this.nightMode == true) {
        localStorage.setItem("nightMode", JSON.stringify(this.nightMode));
        document.querySelector("#app").classList.remove("dark");
        document.querySelector("#icon").classList.remove("fa-sun");
        document.querySelector("#icon").classList.add("fa-moon");
        document.querySelector("#icon").classList.remove("text-warning");
        document.querySelector("#icon").classList.add("text-white");
      } else if (this.nightMode == false) {
        localStorage.setItem("nightMode", JSON.stringify(this.nightMode));
        document.querySelector("#app").classList.add("dark");
        document.querySelector("#icon").classList.remove("fa-moon");
        document.querySelector("#icon").classList.add("fa-sun");
        document.querySelector("#icon").classList.remove("text-white");
        document.querySelector("#icon").classList.add("text-warning");
      }
    },
  },
  created() {
    this.nightMode = JSON.parse(localStorage.getItem("nightMode"));
  },
};
</script>

<style lang="scss" scoped>
details .mode {
  cursor: pointer;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 10px;
}
.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}
.toggler {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #212529;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.toggler:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:focus + toggler {
  box-shadow: 0 0 2px #2196f3;
}
input:checked + .toggler:before {
  -webkit-transform: translateX(31px);
  -ms-transform: translateX(31px);
  transform: translateX(31px);
}
.toggler.round {
  border-radius: 34px;
  width: 65px;
}
.toggler.round:before {
  border-radius: 50%;
}
#sun {
  float: left;
  padding-top: -3px;
  padding-left: 4px;
  font-size: 1.3rem;
}
#moon {
  float: right;
  padding-top: -3px;
  padding-right: 3px;
  font-size: 1.3rem;
}
</style>
